<template>


    <div class="container">
        <div class="table-responsive">
            <table class="table" ref="mailLogTable">
                <thead>
                    <tr><th colspan="3">Delete Mail Queue</th></tr>
                </thead>
                <tbody>
                <tr>
                    <td><button class="btn btn-danger btn-sm" @click="deleteAll(0)"><i class="fa fa-trash" aria-hidden="true"></i> Delete Queue</button></td>
                    <td><button class="btn btn-danger btn-sm" @click="deleteAll(2)"><i class="fa fa-trash" aria-hidden="true"></i> Delete Fail</button></td>
                    <td><button class="btn btn-danger btn-sm" @click="deleteAll(1)"><i class="fa fa-trash" aria-hidden="true"></i> Delete Success</button></td>
                </tr>
            </tbody>
            </table>
        </div>
        
        <button class="btn btn-success btn-sm" style="float: right;" @click="autoRefresh()"><i class="fa fa-refresh" aria-hidden="true"></i> Refresh</button>
        
        <hr />
        
        <div class="table-responsive">
            <table class="table" ref="mailLogTable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Mail Id</th>
                        <th>Mail Subject</th>
                        <th>Mail mail Time</th>
                        <th>Mail Status</th>
                        <th>Mail Atempts</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(maillog, index) in mailsendjob"  :key="maillog.id">
                        <td>{{ index+1 }}</td>
                        <td>{{ maillog.emailId }}</td>
                        <td>{{ maillog.mailSubject }}</td>
                        <td>{{ maillog.mailSendTime }}</td>
                        <td v-if="maillog.mailStatus==1">Success</td>
                        <td v-if="maillog.mailStatus==0" >In Queue</td>
                        <td v-if="maillog.mailStatus==2" >Fail</td>
                        <td>{{ maillog.attempts }}</td>
                        <td><button class="btn btn-danger btn-sm" @click="deletedata(maillog.id)"><i class="fa fa-trash" aria-hidden="true"></i> Delete</button> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col">
                <Spinner />
            </div>
        </div>
    </div>
</template>

<script>


import { MailSendJob } from '../services/MailSendJob'

import Spinner from '../components/Spinner.vue';
import { useUserStore } from '../stores/userstore';
import $ from "jquery";
import dt from "datatables.net";
$(function () {
    //  alert('ok');
    
});
export default {
    name: 'MailSendJob',
    components: {
        Spinner
    },
    setup() {
        const store = useUserStore();

        return {
            store
        }
    },
    data() {
        return {
            form: {
                id: '',

            },
            deleteForm :{
                key:''
            }
            ,
            dt: dt,
            loading: false,
            mailsendjob: [],
            errors: []
        }
    },
    async created() {

        try {
            this.loading = true;
            let response = await MailSendJob.getMailSendJobs();
            //   console.log(response.data);
            this.mailsendjob = response.data;
            this.loading = false
        }
        catch (error) {
            this.errors = error;
            this.loading = false;
        }

    },
    mounted() {
        this.dt = $(this.$refs.mailLogTable).DataTable();
        // this.autoRefresh()
    },
    watch: {
        mailsendjob() {
            this.dt.destroy();
            this.$nextTick(() => {
                this.dt = $(this.$refs.mailLogTable).DataTable()
            })
        }
    }, methods: {
        async deletedata(id) {
            let result = await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            // alert(result.isConfirmed);
            if (result.isConfirmed) {
                this.loading = true;
                const resDel = await MailSendJob.deleteMailSendJob(id)
                // console.log(resDel);
                if (resDel.status == 204) {
                    this.$swal(
                        'MailLog Deleted',
                        resDel.data.status,
                        'success'
                    );
                }

            }

            // alert(id);

            // this.loading = true;
            let response = await MailSendJob.getMailSendJobs();
            this.mailsendjob = response.data;
            this.loading = false;
        },
        async loadMailsendjob(){
            this.loading =true;
            let response = await MailSendJob.getMailSendJobs();
            this.mailsendjob = response.data;
            this.loading = false;
            
        },
        async deleteAll(key){
            let result = await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            this.deleteForm.key =key;
            // alert(result.isConfirmed);
            if (result.isConfirmed) {
                this.loading = true;
                const resDel = await MailSendJob.deleteAll(this.deleteForm);
                // console.log(resDel);
                if (resDel.status == 204) {
                    this.$swal(
                        'MailLog Deleted',
                        resDel.data.status,
                        'success'
                    );
                }

            }

            // alert(id);

            // this.loading = true;
            let response = await MailSendJob.getMailSendJobs();
            this.mailsendjob = response.data;
            this.loading = false;
        },
        autoRefresh(){
            // setInterval(()=>{
                this.loadMailsendjob()
            // },3000);
        }

    },
}
</script>
<style scoped>
.table > thead {
    vertical-align: bottom;
    background: lightskyblue;
}
tbody tr td {
    border-right: 1px solid #a7daf9;
}
tbody tr td:first-child {
    border-left: 1px solid #a7daf9;
}


tr.odd  { background-color:#FFF; vertical-align: middle;}
tr.even { background-color:#e5f5ff; vertical-align: middle;}
</style>