import axios from "./axios.js"

export class MailSendJob {

    static getMailSendJobs() {
        return axios.get("/mailsendjob");

    }
    static getMailSendJob(id) {
        let defaultURL = `/mailsendjob/${id}`;
        return axios.get(defaultURL);
    }
    static createMailSendJob(mailsendjob) {
        let defaultURL = `/mailsendjob`;
        return axios.post(defaultURL, mailsendjob);
    }
    static updateMailSendJob(mailsendjob, id) {
        let defaultURL = `/mailsendjob/${id}`;
        return axios.put(defaultURL, mailsendjob);
    }
    static deleteMailSendJob(id) {
        let defaultURL = `/mailsendjob/${id}`;
        return axios.delete(defaultURL);
    }
    static deleteAll(form){
        let defaultURL = `/mailjobdelete`;
        return axios.post(defaultURL,form);
    }

}